import { useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Outlet } from "react-router-dom";
import Copyright from "../lib/components/copyright";

export default function MainLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const drawerWidth = 240;

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
    open?: boolean;
  }>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }));

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar
            sx={{
              backgroundColor: "white",
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            {/* <Box sx={{ paddingLeft: drawerOpen ? 50 : 0 }}> */}
            <IconButton color="primary" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>

            <a href="/">
              <Typography sx={{ paddingLeft: drawerOpen ? 30 : 0, fontFamily: "Courier" }}>Robert Murphy</Typography>
            </a>
            {/* </Box> */}
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          anchor="left"
          variant="persistent"
          open={drawerOpen}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem>
              <ListItemButton component="a" href="bw">
                <ListItemText primary="BW" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component="a" href="color">
                <ListItemText primary="COLOR" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component="a" href="tri">
                <ListItemText primary="TRIATHLON" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component="a" href="other">
                <ListItemText primary="OTHER" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component="a" href="film">
                <ListItemText primary="FILM" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton component="a" href="https://www.flickr.com/photos/robertmurphyphotography/albums">
                <ListItemText primary="FLICKR" />
              </ListItemButton>
            </ListItem>            
            <ListItem>
              <ListItemButton component="a" href="about">
                <ListItemText primary="ABOUT" />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Main open={drawerOpen} sx={{ backgroundColor: "white" }}>
          <Box component="main">
            {/* An <Outlet> renders whatever child route is currently active,
              so you can think about this <Outlet> as a placeholder for
              the child routes we defined above. */}
            <Box sx={{ height: 50 }}></Box>
            <Outlet />
            <footer>
              <Copyright sx={{ pt: 4 }} />
            </footer>
          </Box>
        </Main>
      </Box>
    </>
  );
}
