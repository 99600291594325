import "./style.css";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

export default function Home() {
  const data = [
    {
      original: "https://robertmurphy-5f76b.web.app//assets//bw//bw-1.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//tri//tri-9.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//color//color-65.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//tri//tri-20.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//bw//bw-4.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//tri//tri-23.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//color//color-64.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//bw//bw-132.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//tri//tri-42.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//color//color-36.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//film//film-4.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//color//color-14.jpg",
      originalHeight: 800,
    },
    {
      original: "https://robertmurphy-5f76b.web.app//assets//tri//tri-21.jpg",
      originalHeight: 800,
    },
  ];

  return (
    <>
      <ImageGallery
        items={data}
        thumbnailPosition="bottom"
        showThumbnails={false}
        autoPlay={true}
        infinite={true}
        showPlayButton={false}
        showBullets={false}
        showNav={false}
        showIndex={false}
        showFullscreenButton={false}
        slideInterval={3000}
        slideDuration={450}
        isRTL={false}
        additionalClass="app-image-gallery"
      />
    </>
  );
}
