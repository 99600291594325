import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import BW from "pages/bw";
import Color from "pages/color";
import Tri from "pages/tri";
import Other from "pages/other";
import Film from "pages/film";
import About from "pages/about";
import Work from "pages/work";
import Home from "home";
import MainLayout from "layouts/main-layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="bw" element={<BW />} />
          <Route path="color" element={<Color />} />
          <Route path="tri" element={<Tri />} />
          <Route path="other" element={<Other />} />          
          <Route path="film" element={<Film />} />
          <Route path="about" element={<About />} />
          <Route path="work" element={<Work />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>So sorry ... You seem to be lost ...</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
