import "../style.css";
import { Grid, Link, Paper, Container, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";

export default function Work() {
  return (
    <Container sx={{ paddingTop: 2 }}>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Paper elevation={16} sx={{ width: 360, height: 360, alignItems: "center" }}>
            <img src="https://robertmurphy-5f76b.web.app/assets/about/robert-murphy.jpg" alt="Robert Murphy" />
          </Paper>
          <br />
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="primary" fontFamily="courier">
            Career - Writing code since 1980
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} fontFamily="courier">
            <Link onClick={() => (window.location.href = "mailto:robert@robertmurphy.com")}>Contact Me</Link>
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <br />
      <List sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}>
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Work Experience"
            secondary={
              <>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Senior Programmer - Cedar Rapids, Iowa
                </Typography>
                <br />
                Ideal Computer Systems, Constellation Software
                <br />
                2015 to Present
                <br />
                <ul>
                  <li>
                    Developed React/Typescript application with Azure C# API functions for managing dealer's
                    manufacturer pricelist information.
                  </li>
                  <li>
                    Developed Windows services to feed on premise customer database data to a consolidated reporting
                    database for analysis and future cloud products.
                  </li>
                  <li>Designed and maintained the companies core order processing system.</li>
                  <li>Worked primarily with Scrum teams in US and Pakistan</li>
                </ul>
                <br />
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Contract Programmer - Jupiter Florida
                </Typography>
                <br />
                Jupiter, Florida
                <br />
                2007 to 2015
                <br />
                <ul>
                  <li>
                    Developed and supported a line of ASP.NET/C# web-based database and reporting applications for
                    radiology groups in Iowa and Colorado.
                  </li>
                  <li>
                    Developed methods to communicate with hospital HL7 based systems to send and receive patient exam
                    reports and demographic information. These systems significantly decreased patient wait time,
                    eliminated duplicate entry and improved data accuracy.
                  </li>
                </ul>
                <br />
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Photographer
                </Typography>
                <br />
                Triathlete Magazine, Inside Triathlon
                <br />
                2004 to 2010
                <br />
                <ul>
                  <li>Triathlon race and portrait photographer shooting for various publications over the world.</li>
                </ul>
                <br />
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Senior Programmer - LimoLink
                </Typography>
                <br />
                Marion, Iowa
                <br />
                2005-2009
                <br />
                <ul>
                  <li>
                    Developed web and Windows based SQL Server database applications and services for the company’s
                    internal order processing system.
                  </li>
                  <li>
                    Developed applications to send and receive limousine orders to/from vendors and customers through
                    the use of web services and socket communications.
                  </li>
                  <li>
                    Developed various C# Windows based utilities to increase the efficiency and accuracy of internal IT
                    tasks.
                  </li>
                  <li>
                    Responsible for programming standards, procedures and the naming conventions for software
                    development.
                  </li>
                </ul>
                <br />
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Programmer - Ideal Computer Systems
                </Typography>
                <br />
                Marion, Iowa
                <br />
                2004-2005
                <br />
                <ul>
                  <li>
                    Worked on a team developing the company’s core software product line for retail shops selling
                    motorcycles, ATVs, outdoor power equipment, etc.
                  </li>
                  <li>
                    Developed automated installation and upgrade systems for easy distribution of the line of software
                    products and pricing structures.
                  </li>
                  <li>
                    Developed an encryption licensing system that secured the system’s various security configurations.
                  </li>
                </ul>
                <br />
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Programmer - Iowa Glass Depot
                </Typography>
                <br />
                Cedar Rapids, Iowa
                <br />
                1996-2004
                <br />
                <ul>
                  <li>
                    Project manager and lead developer of the company’s first web-based order processing system that
                    provided wholesale and retail customers the ability to generate orders via the internet. Involved in
                    all phases of the project life cycle with major roles in the development of middle-tier web
                    services.
                  </li>
                  <li>
                    Designed and developed a line of Windows-based order processing and inventory tracking systems,
                    database and application security maintenance utilities, help-desk incident tracking system,
                    automated email and FTP communication systems, network utilities bridging the use of Active
                    Directory, Citrix and the user’s application interface.
                  </li>
                  <li>
                    Developed and documented a full set of programming standards, naming conventions and various
                    software related procedures.
                  </li>
                  <li>Database and systems administrator for the AS/400.</li>
                </ul>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Skills"
            secondary={
              <>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Languages, Tools, Platforms, Operating Systems, etc.
                </Typography>
                <br />
                <ul>
                  <li>C# - Windows, Windows Services, REST APIs, etc.</li>
                  <li>Javascript/Typescript and React</li>
                  <li>Delphi/Pascal</li>
                  <li>Pyton</li>
                  <li>ASP.NET Core</li>
                  <li>NPM/NodeJS</li>
                  <li>MS SQL</li>
                  <li>Azure Functions, Web Apps, Authentication</li>
                  <li>MS DevOps</li>
                  <li>Teams</li>
                  <li>GitHub</li>
                  <li>Visual Studio, VS Code</li>
                  <li>Windows, Linux, DOS, OS X, Powershell</li>
                  <li>Interfaces to Square, Quickbooks, Twilio</li>
                  <li>Scrum</li>
                  <li>Photoshop, Affinity, Lightroom</li>
                </ul>
              </>
            }
          />
        </ListItem>
        <Divider />
        <ListItem alignItems="flex-start">
          <ListItemText
            primary="Education"
            secondary={
              <>
                <Typography sx={{ display: "inline" }} component="span" variant="body2" color="secondary">
                  Iowa State University, Ames, Iowa
                </Typography>
                <br />
                BS in Computer Science
                <br />
                Minor in English
                <br />
                Scholarship and Acceptance with High Honors
              </>
            }
          />
        </ListItem>
      </List>
    </Container>
  );
}
