import React from "react";
import ReactDOM from "react-dom";
import "./style.css";
import App from "./App";

ReactDOM.render(
  // StrictMode helps find any issues within the children
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
