import React, { useState, useCallback } from "react";
import { Container } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

export default function Other() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    "https://robertmurphy-5f76b.web.app//assets//other//other-1.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-2.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-3.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-4.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-5.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-6.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-7.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-8.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-9.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-10.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-11.jpg",
    "https://robertmurphy-5f76b.web.app//assets//other//other-12.jpg",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container>
      {images.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width="300"
          key={index}
          style={{ margin: "2px" }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
}
