import React, { useState, useCallback } from "react";
import { Container } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

export default function BW() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-1.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-2.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-3.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-4.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-5.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-6.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-7.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-9.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-10.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-11.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-12.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-13.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-14.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-15.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-16.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-17.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-19.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-20.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-21.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-22.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-23.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-24.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-25.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-26.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-27.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-28.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-29.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-30.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-32.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-33.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-34.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-35.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-36.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-37.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-38.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-39.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-40.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-41.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-42.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-43.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-44.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-45.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-46.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-47.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-48.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-49.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-50.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-51.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-53.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-54.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-55.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-56.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-57.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-58.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-59.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-60.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-61.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-62.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-63.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-64.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-65.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-66.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-67.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-68.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-69.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-70.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-71.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-72.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-73.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-74.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-75.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-76.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-77.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-78.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-79.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-80.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-82.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-83.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-84.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-85.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-86.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-87.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-88.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-90.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-91.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-92.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-93.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-94.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-95.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-96.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-97.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-98.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-99.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-100.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-101.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-102.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-103.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-104.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-105.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-106.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-107.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-108.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-109.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-110.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-111.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-112.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-113.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-114.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-115.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-116.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-117.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-118.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-119.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-120.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-121.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-122.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-123.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-124.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-125.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-126.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-127.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-128.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-129.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-130.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-131.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-132.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-133.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-134.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-135.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-136.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-137.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-138.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-139.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-140.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-141.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-142.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-143.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-144.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-145.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-147.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-148.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-149.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-150.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-151.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-152.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-153.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-155.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-156.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-157.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-159.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-160.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-161.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-162.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-163.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-164.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-165.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-166.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-167.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-168.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-169.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-170.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-171.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-174.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-175.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-177.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-178.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-179.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-181.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-183.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-184.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-185.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-187.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-188.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-189.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-190.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-191.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-192.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-193.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-194.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-195.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-196.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-197.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-198.jpg",
    "https://robertmurphy-5f76b.web.app//assets//bw//bw-199.jpg",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container>
      {images.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width="300"
          key={index}
          style={{ margin: "2px" }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
}
