import React, { useState, useCallback } from "react";
import { Container } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

export default function Color() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    "https://robertmurphy-5f76b.web.app//assets//color//color-1.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-2.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-3.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-4.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-5.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-6.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-7.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-8.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-9.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-10.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-11.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-12.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-13.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-14.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-15.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-16.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-17.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-18.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-19.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-20.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-21.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-22.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-23.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-24.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-25.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-26.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-27.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-28.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-29.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-30.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-31.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-32.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-33.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-34.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-35.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-36.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-37.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-38.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-39.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-40.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-41.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-42.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-43.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-44.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-45.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-46.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-47.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-48.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-49.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-50.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-51.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-52.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-53.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-54.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-55.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-56.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-57.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-58.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-59.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-60.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-61.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-62.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-63.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-64.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-65.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-66.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-67.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-68.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-69.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-70.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-71.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-72.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-73.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-74.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-75.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-76.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-77.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-79.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-80.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-81.jpg",
    "https://robertmurphy-5f76b.web.app//assets//color//color-82.jpg",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container>
      {images.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width="300"
          key={index}
          style={{ margin: "2px" }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
}
