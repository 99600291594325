import "../style.css";
import { Grid, Link, Paper, Card, CardContent, Container, Divider, Typography } from "@mui/material";

export default function About() {
  return (
    <Container sx={{ paddingTop: 2 }}>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Paper elevation={16} sx={{ width: 360, height: 360, alignItems: "center" }}>
            <img src="https://robertmurphy-5f76b.web.app/assets/about/robert-murphy.jpg" alt="Robert Murphy" />
          </Paper>
          <br />
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} fontFamily="courier">
            <Link onClick={() => (window.location.href = "mailto:robert@robertmurphy.com")}>Contact Me</Link>
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <br />
      <Typography sx={{ fontSize: 24, fontFamily: "courier" }} color="primary" fontFamily="courier">
        Running & Triathlon
      </Typography>
      <br></br>
      {/* <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            Dad's Boston - 1984
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            2:54:26
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider> */}
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            1995 Chicago (First Marathon)
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:20:35
            <br />
            Injured Knee. Slow start (before chips) <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            1996 Grandma's Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            2:58:28
            <br />
            Division: 89/1543
            <br />
            Overall: 203/5363
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            1998 Madison Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:04:41
            <br />
            Division: 10
            <br />
            Overall: 42
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            1999 Boston Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:29:20
            <br />
            Division: 192/2909
            <br />
            Overall: 833/24672
            <br />
            57 degrees
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            1999 Chicago Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            2:58:48 <br />
            Division: 192/2909
            <br />
            Overall: 833/24672
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2000 Great Floridian Ironman Distance Triathlon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            ?
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2001 Grandma's Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:14:53
            <br />
            Division: 120/1467
            <br />
            Overall: 328/6701
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2002 Wisconsin Ironman
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            12:50:32
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2004 Las Vegas Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:09:27
            <br />
            Strong Headwinds
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2004 Boston Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:32:19
            <br />
            Division: 1,290 / 3,995
            <br />
            Overall: 2,226 / 16,743
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2010 Palm Beach Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:18:27
            <br />
            Division: 10
            <br />
            Overall Place: 40
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2013 Marion Marathon (Inaugural)
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:09:04
            <br />
            Division: 1st
            <br />
            Overall: 6th
            <br />
          </Typography>
        </CardContent>
      </Card>

      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2016 Grandmas Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:20:07
            <br />
            Division: 21/413
            <br />
            Overall: 417/7518
            <br />
          </Typography>
        </CardContent>
      </Card>

      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2017 Boston Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            03:18:27
            <br />
            Division: 525 / 2,910
            <br />
            Overall: 4,329 / 27,221 <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2018 Boston Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:11:14
            <br />
            Overall: 3561 / 29978 (entered) / 26948(started)
            <br />
            Division : 102 / 2167(entered)/1940(started)
            <br />
            Coldest Boston / Constant rain and headwinds
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2020 Osage Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:13:59
            <br />
            Overall: 8 / 72
            <br />
            Division: 1st
            <br />
              Suffered from Heat Exhaustion (August in Iowa)
              <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2020 Equalizer Endurance Run - 6 Hours
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            42.66 Miles
            <br />
            1st Overall - Most Miles & Equalizer
            <br />
          </Typography>
        </CardContent>
      </Card>
      <Divider></Divider>
      <Card sx={{ minWidth: 275, marginBottom: 2 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
            2021 Boston Marathon
          </Typography>
          <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
            3:16:48
            <br />
            Division: 286/1116
            <br />
            Overall: 3466/15385
            <br />
            Became sick about a week out from the start of the race
            <br />
          </Typography>
        </CardContent>
      </Card>
        <Divider></Divider>
        <Card sx={{ minWidth: 275, marginBottom: 2 }}>
            <CardContent>
                <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
                    2024 Eau Claire Marathon
                </Typography>
                <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
                    3:16:00
                    <br />
                    Division: 2/46
                    <br />
                    Overall: 70/627
                    <br />
                </Typography>
            </CardContent>
        </Card>
        <Divider></Divider>
        <Card sx={{ minWidth: 275, marginBottom: 2 }}>
            <CardContent>
                <Typography sx={{ fontSize: 18, fontFamily: "courier" }} color="secondary" gutterBottom>
                    2024 Des Moines Marathon
                </Typography>
                <Typography sx={{ fontSize: 12, fontFamily: "courier" }} component="div">
                    3:14:40
                    <br />
                    Division: 2/53
                    <br />
                    Overall: 86/1482
                    <br />
                </Typography>
            </CardContent>
        </Card>
    </Container>
  );
}
