import React, { useState, useCallback } from "react";
import { Container } from "@mui/material";
import ImageViewer from "react-simple-image-viewer";

export default function Tri() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const images = [
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-1.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-2.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-3.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-4.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-5.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-6.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-7.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-8.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-9.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-11.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-12.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-13.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-14.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-15.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-16.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-17.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-18.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-19.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-20.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-21.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-22.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-23.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-24.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-25.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-26.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-27.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-28.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-29.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-30.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-31.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-32.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-33.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-34.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-35.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-36.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-37.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-38.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-39.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-40.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-41.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-42.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-43.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-44.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-45.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-46.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-47.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-48.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-49.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-50.jpg",
    "https://robertmurphy-5f76b.web.app//assets//tri//tri-51.jpg",
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Container>
      {images.map((src, index) => (
        <img
          src={src}
          onClick={() => openImageViewer(index)}
          width="300"
          key={index}
          style={{ margin: "2px" }}
          alt=""
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </Container>
  );
}
